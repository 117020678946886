<h1 mat-dialog-title>
  {{title}}
</h1>

<div mat-dialog-content>
  <p>{{message}}</p>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onDismiss()">Non</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">Oui</button>
</div>
