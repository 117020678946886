<mat-form-field>
  <mat-label>Workspace</mat-label>
  <input matInput [(ngModel)]="searchString" (keydown)="keydownSearch()">
</mat-form-field>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding  matTreeNodePaddingIndent="20" >

    <button mat-icon-button disabled></button>
    <mat-checkbox color="primary" class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
      (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20"
    [style.display]="filterLeafNode(node) ? 'none' : 'block'">
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox color="primary" [checked]="descendantsAllSelected(node)"
      [indeterminate]="descendantsPartiallySelected(node)" (change)="todoItemSelectionToggle(node)">{{node.item}}
    </mat-checkbox>

  </mat-tree-node>
</mat-tree>