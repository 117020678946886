// Libs
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { ConfigService } from 'src/app/shared/services/core/config.service';
import { Module } from '../../interfaces/module';

// Interfaces


@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) { }

  /**
   * Fetch Modules
   */
  public fetchModules(moduleName?: string): Observable<Module[]> {
    let params = new HttpParams();
    if (moduleName) {
      params.set('moduleName', moduleName);
    }
    return this.http.get<Module[]>(`${this.configService.config.admin.url}/module`, { params });
  }

  /**
   * Fetch related modules in a structure
   */
  public fetchRelatedRoles(structureId: number): Observable<Module[]> {
    return this.http.get<Module[]>(`${this.configService.config.admin.url}/module/${structureId}`);
  }

  public fetchModulesNavigation(): Observable<Module[]> {
    return this.http.get<Module[]>(`${this.configService.config.admin.url}/module/navigation`);
  }
}
