// Libs
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

// Interfaces
import { NavElement } from 'src/app/shared/interfaces/sidenav';
import { AccountService } from 'src/app/shared/services/auth/account.service';
import { SidenavService } from 'src/app/shared/services/business/sidenav.service';
import { Utilisateur } from '../../interfaces/utilisateur';
import { AuthenticationService } from '../../services/auth/authentication.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  navElements: NavElement[];
  currentNavUrl: string;
  user: Utilisateur

  constructor(
    private sidenaveService: SidenavService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private accountService: AccountService,
  ) {
    this.accountService.currentUser.subscribe(user => {
      this.user = user
    });
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentNavUrl = event.url;
      });
    this.navElements = this.sidenaveService.navElements;
  }

  /**
   * Deconnects the user
   */
  logout(): void {
    this.authenticationService
      .logoffAndRevokeTokens();
  }

  /**
   * Access to one's account
   */

  account(): void {
    this.router.navigate([`/utilisateur/${this.user.id}`]);
  }
}
