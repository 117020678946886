// Libs
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { ConfigService } from 'src/app/shared/services/core/config.service';

// Interfaces
import { EntityFilters, PostEntityPayload, PutEntityPayload } from '../../interfaces/entity';


@Injectable({
  providedIn: 'root'
})
export abstract class EntityService<T> {

  constructor(
    protected configService: ConfigService,
    protected http: HttpClient,
    protected path: string,
  ) { }

  /**
   * Fetch all entities
   */
  public fetchAndCountAll(filters: EntityFilters = null, pageSize: number = null, pageIndex: number = null, sortBy: string = null, orderBy: string = null): Observable<{rows: T[], count: number }> {
    let params = new HttpParams();
    if (filters) {
      Object.entries(filters).map(([key, value]) => {
        params = params.set(key, (value as string));
      });
    }
    if (pageSize) params = params.set('limit', pageSize);
    if (pageIndex && pageSize) params = params.set('offset', pageIndex * pageSize);
    if (sortBy) params = params.set('sortBy', sortBy);
    if (orderBy) params = params.set('orderBy', orderBy);
    return this.http.get<{rows: T[], count: number }>(`${this.configService.config.admin.url}/${this.path}`, { params });
  }

  /**
   * Fetch an entity by id
   * @param id
   */
  public fetchById(id: number): Observable<T> {
    return this.http.get<T>(`${this.configService.config.admin.url}/${this.path}/${id}`);
  }


  /**
   * Register entity
   * @param postData data of the entity to register
   */
  public register(postData: PostEntityPayload): Observable<T> {
    return this.http.post<T>(`${this.configService.config.admin.url}/${this.path}`, postData);
  }

  /**
   * Update an entity
   * @param id id of the entity
   * @param putData updated data of the entity
   */
  public update(id: number, putData: PutEntityPayload): Observable<T> {
    return this.http.put<T>(`${this.configService.config.admin.url}/${this.path}/${id}`, putData);
  }

  /**
   * Delete an entity
   * @param id id of the entity
   */
  public delete(id: number): Observable<any> {
    
    return this.http.delete<any>(`${this.configService.config.admin.url}/${this.path}/${id}`);
  }
}
