// Libs
import {Component, OnInit} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';

// Services
import {AccountService} from 'src/app/shared/services/auth/account.service';

// Components
import {SubscribableComponent} from 'src/app/shared/utils/subscribable-component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends SubscribableComponent implements OnInit {
  newAuthInit: boolean;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private accountService: AccountService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.push(
      this.accountService.isLogged.subscribe(async isLogged => {
        this.newAuthInit = isLogged;
        if(isLogged) {
          await this.accountService.fetchMe().toPromise();
        }
        await this.accountService.updateAbility();
      })
    )
    this.newAuthInit = this.accountService.isLogged.value;
  }
}

