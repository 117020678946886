// Libs
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IsSignedInGuard } from './shared/guard/IsSignedInGuard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
    canActivate: [IsSignedInGuard]

  },
  {
    path: 'utilisateur',
    loadChildren: () => import('./utilisateur/utilisateur.module').then(mod => mod.UtilisateurModule),
    canActivate: [IsSignedInGuard]

  },
  {
    path: 'role',
    loadChildren: () => import('./role/role.module').then(mod => mod.RoleModule),
    canActivate: [IsSignedInGuard]

  },
  {
    path: 'structure',
    loadChildren: () => import('./structure/structure.module').then(mod => mod.StructureModule),
    canActivate: [IsSignedInGuard]
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./unauthorized/unauthorized.module').then(mod => mod.UnauthorizedModule),
  },
  {
    path: 'no-account',
    loadChildren: () => import('./no-account/no-account.module').then(mod => mod.NoAccountModule),
  },
  {
    path: 'lost',
    loadChildren: () => import('./lost/lost.module').then(mod => mod.LostModule),
  },

  {
    path: 'demande',
    loadChildren: () => import('./demande/demande.module').then(mod => mod.demandeModule),
  },
  {
    path: '**',
    redirectTo: 'lost',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
