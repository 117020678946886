<div role="group"
     class="structure-select-container"
     [attr.aria-labelledby]="_formField?.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
<input
  class="structure-select-element"
  style="width: 500px;"
  type="text"
  [value]="displayFn(value)"
  [matAutocomplete]="auto"
  required
  [disabled]="this.disabled"
  (keyup)="searchText.next($event.target.value)"
  (focus)="searchText.next($event.target.value)"
  #structure
>

<mat-autocomplete
  autoActiveFirstOption
  #auto="matAutocomplete"
  [displayWith]="displayFn"
  (optionSelected)="onOptionSelected($event.option.value)"
>
  <mat-option
    *ngFor="let structure of structures | async"
    [value]="structure"
    class="liste-affichage"
  >
    {{structure?.metadata?.lieuIdentifiant}} {{structure.nom}}
  </mat-option>
</mat-autocomplete>
</div>

