import { Component, ViewChild, Input, OnChanges, Output, EventEmitter, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Role, RoleFilters } from '../../interfaces/role';
import { merge } from 'rxjs';
import { EntityPaginableDataSource } from '../../services/datasource/entity-paginable.datasource';
import { EtatUtilisateur, Habilitation, Utilisateur } from '../../interfaces/utilisateur';
import { tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { IMetadata, StructureType } from '../../interfaces/structure';
import { UtilisateurService } from '../../services/http/utilisateur.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges, AfterViewInit {
  @Input() displayedColumns: string[] = [];
  @Input() columnsLabels: string[] = [];
  @Input() filtres: RoleFilters;
  @Input() paginableDataSource: EntityPaginableDataSource<Role | Utilisateur>;
  @Input() data: any[];
  @Input() paginable: boolean = true;
  @Input() canEdit: (data: any) => boolean = (data) => true;
  @Input() canDelete: (data: any) => boolean = (data) => true;
  @Output() edit = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();
  @Output() utilisateurClick = new EventEmitter<any>();
  @Output() enfantClick = new EventEmitter<any>();

  dataSource: MatTableDataSource<any>;
  dataColumns: string[];
  etats = {
    [EtatUtilisateur.ACTIF]: 'Actif',
    [EtatUtilisateur.INACTIF]: 'Inactif',
    [EtatUtilisateur.PENDING]: 'En attente'
  };
  structureType = StructureType;
  Imetadata : IMetadata;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedHabilitations: Habilitation[] = [];

  constructor(private utilisateurService: UtilisateurService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filtres && changes.filtres.currentValue && this.paginable) {
      if (changes.filtres.previousValue === null) {
        this.paginableDataSource.loadData(this.filtres, 13, 0, '', '');
      } else {
        this.loadData();
      }
    } else {
      if (this.displayedColumns && this.data) {
        this.dataSource = new MatTableDataSource<any>(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  ngAfterViewInit() {
    if (this.paginable) {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(tap(() => this.loadData()))
        .subscribe();
    }
  }

  loadData() {
    if (this.paginable) {
      this.paginableDataSource.loadData(
        this.filtres,
        this.paginator.pageSize,
        this.paginator.pageIndex,
        this.sort.active && this.sort.direction ? this.sort.active : '',
        this.sort.direction && this.sort.active ? this.sort.direction : '',

      )
    }
  }

  delete(element) {
    this.remove.emit(element);
  }

  async getHabilitations(utilisateurId: number) {
    this.displayedHabilitations = [];
    this.utilisateurService.fetchhabilitations(utilisateurId).subscribe(habilitations => {
      this.displayedHabilitations = habilitations;
    });
  }
}

export function getFrenchPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Elements par page:';
  paginatorIntl.nextPageLabel = 'Page suivante';
  paginatorIntl.previousPageLabel = 'Page précédente';

  return paginatorIntl;
}
