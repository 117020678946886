// Libs
import {Injectable} from '@angular/core';
import {Ability, AbilityBuilder} from '@casl/ability';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {BehaviorSubject, Observable} from 'rxjs';
import {share, tap} from 'rxjs/operators';

// Interfaces
import {UtilisateurService} from 'src/app/shared/services/http/utilisateur.service';
import {Utilisateur} from '../../interfaces/utilisateur';
import {RoleService} from '../http/role.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  isLogged = new BehaviorSubject<boolean>(false);
  currentUser = new BehaviorSubject<Utilisateur>(null)

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private utilisateurService: UtilisateurService,
    private ability: Ability,
    private roleService: RoleService
  ) {
  }

  fetchMe(): Observable<Utilisateur> {
    return this.utilisateurService.fetchMe().pipe(
      tap(user => this.currentUser.next(user))
    )
  }
  async updateAbility(): Promise<void> {
    const { can, rules } = new AbilityBuilder(Ability);
    if (this.currentUser.value) {
      const roles = await this.roleService.fetchRelatedRoles().toPromise();
      // super admin case
      if (roles.some(role => role.id === 1)) {
        can('manage', 'all')
      } else {
        await Promise.all(this.currentUser.value.structures.map(async (structure) => {
          let role = roles.find(r => r.id === structure.StructureRoleUtilisateur.roleId)
          const collegues$ = this.utilisateurService.fetchUsersOfStructure(structure.id).pipe(share())
          const filteredPermission = role.permissions.filter(p => p.moduleId === 1)
          await Promise.all(filteredPermission.map(async p => {
            if (p.action.includes('Utilisateur')) {
              const collegues = await collegues$.toPromise()as Utilisateur[];
              can(p.action, 'Admin', { utilisateurId: { $in: collegues.map(u => u.id) } });
            } else if (p.action.includes('Structure')) {
              can(p.action, 'Admin', { structureId: structure.id });
              if (p.action.includes('read')) {
                can(structure.type, 'Admin');
              }
            } else {
              can(p.action, 'Admin')
            }
          }))
        }))
      }
    }
    // remove duplicated rules
    let filteredRules = rules.filter((rule, index, self) =>
      self.findIndex(r => r.action === rule.action && r.subject === rule.subject && r.conditions === rule.conditions) === index)
    this.ability.update(filteredRules);

    // if user can read user display user list
    if (this.ability.can('readUtilisateur', 'Admin')) {
      can('utilisateurList', 'Admin')
    }
    // user can read et edit himself
    if (this.currentUser.value) {
      can('readUtilisateur', 'Admin', { utilisateurId: this.currentUser.value.id })
      can('updateUtilisateur', 'Admin', { utilisateurId: this.currentUser.value.id })
    }
    can('', 'Admin');
    this.ability.update(filteredRules);

    // can be inproved with alias
    // rule if user can edit demande
    if (this.ability.can('createUtilisateur', 'Admin') && this.ability.can('updateUtilisateur', 'Admin')) {
      can('manageDemande', 'Admin')
      filteredRules = rules.filter((rule, index, self) =>
        self.findIndex(r => r.action === rule.action && r.subject === rule.subject && r.conditions === rule.conditions) === index)
      this.ability.update(filteredRules);
    }
  }
}
