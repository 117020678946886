import { Injectable, OnInit } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { AccountService } from "../services/auth/account.service";
import { SubscribableComponent } from "../utils/subscribable-component";
import {UtilisateurService} from "../services/http/utilisateur.service";

@Injectable({
  providedIn: 'root'
})
export class IsSignedInGuard extends SubscribableComponent implements CanActivate {


  newAuthInit: boolean;
  refreshInit: Promise<boolean>;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private accountService: AccountService,
    private utilisateurService: UtilisateurService
  ) {
    super();
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {


    return this.oidcSecurityService
      .checkAuth()
      .pipe(catchError((err) => {
        console.log(err)
        return of({ isAuthenticated: false })
      }))
      .pipe(map( ({ isAuthenticated }) => {
        this.accountService.isLogged.next(isAuthenticated)
        if (!isAuthenticated) {
          this.oidcSecurityService.authorize();
        }
        return isAuthenticated;

      }));




  }
}


