<app-stretch-view stretchColumnAlign="center stretch">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header-container">
    <div class="header-title">
      PETSTORE-UI
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <mat-icon class="header-profile-icon pointer">account_circle</mat-icon>
      <div class="name-font">{{user.nom}}</div>
      <mat-icon (click)="logout()" class="header-logout-icon pointer" matTooltip="Logout" matTooltipPosition="below">
        logout</mat-icon>
    </div>
  </div>
</app-stretch-view>
