// Libs
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, switchMap, filter, take, mergeMap } from 'rxjs/operators';

// Services
import { ConfigService } from 'src/app/shared/services/core/config.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationService } from 'src/app/shared/services/auth/authentication.service';

// Interfaces
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {AccountService} from "../auth/account.service";

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  gettingRefreshToken: boolean;

  initialUnauthenticatedLength: number;

  unauthenticatedRoutesPrefix: string[] = [
    `${environment.api}/config/`,
  ];

  RoutesPrefix: string[] = [];

  authenticatedRoutesPrefix: string[] = [
    `/api`,
    `/api`.replace(/^\//, ''),
  ];

  token: string;

  constructor(
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private accountService: AccountService
  ) {
    this.initialUnauthenticatedLength = this.unauthenticatedRoutesPrefix.length;
    this.oidcSecurityService.getAccessToken().subscribe({
      next: (token) => {
        this.token = token;
      },
      error: (err) => console.log(err)
    });
  }

  /**
   * Intercept outbound requests
   *
   * * Adds authentication headers if needed
   *
   * @param req The intercepted request
   * @param next The http handler
   * @return response handling
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) { //: Observable<HttpEvent<any>> | Observable<undefined> {
    return this.oidcSecurityService.getAccessToken().pipe(mergeMap(token => {
      this.token = token;
      if (this.unauthenticatedRoutesPrefix.length === this.initialUnauthenticatedLength && this.configService.loaded) {
        this.RoutesPrefix.push(this.configService.config.keycloak.url);
      }

      // Remove scheme://domain and leading /
      const path = req.url.replace(/^(https?:\/\/)(.*?)\//, '');

      if (this.RoutesPrefix.some((route) => req.url.startsWith(route)) ||
        this.unauthenticatedRoutesPrefix.some((route) => path.startsWith(route)) ||
        !this.authenticatedRoutesPrefix.some((route) => path.startsWith(route))) {
        return next.handle(req);
      }

      return next.handle(this.addPolicies(req))
        .pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401 && !this.gettingRefreshToken) {
              this.gettingRefreshToken = true;

              return this.oidcSecurityService.forceRefreshSession()
                .pipe(
                  filter(result => result !== null),
                  take(1),
                  switchMap(() => {
                    this.gettingRefreshToken = false;
                    return next.handle(this.addPolicies(req));
                  }),
                  catchError(() => {
                    if (this.oidcSecurityService.getAccessToken() && req.url === '/api/admin/utilisateur/me') {
                      this.accountService.isLogged.next(false);
                      this.router.navigate(['/no-account']);
                      return of(undefined);
                    }
                    this.authenticationService.logoffAndRevokeTokens();
                    return of(undefined);
                  })
                );
            } else if (err.status === 403) {
              this.router.navigateByUrl('unauthorized')
            }
            return throwError(() => new Error(err.error));
          })
        );
    }))
  }

  /**
   * Increments a request with required bits for authentication
   * @param req The request to enhance
   */
  addPolicies(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.token}`,
      }
    });
  }
}
