// Libs
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';

// Services
import { ConfigService } from 'src/app/shared/services/core/config.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthenticationService } from 'src/app/shared/services/auth/authentication.service';

// Interfaces
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  gettingRefreshToken: boolean;

  initialUnauthenticatedLength: number;

  unauthenticatedRoutesPrefix: string[] = [
    `${environment.api}/config/`,
  ];

  RoutesPrefix: string[] = [];

  authenticatedRoutesPrefix: string[] = [
    `/api`,
    `/api`.replace(/^\//, ''),
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
    private oidcSecurityService: OidcSecurityService,
    private router: Router
  ) {
    this.initialUnauthenticatedLength = this.unauthenticatedRoutesPrefix.length;
  }

  /**
   * Intercept outbound requests
   *
   * * Adds authentication headers if needed
   *
   * @param req The intercepted request
   * @param next The http handler
   * @return response handling
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | Observable<undefined> {
    if (this.unauthenticatedRoutesPrefix.length === this.initialUnauthenticatedLength && this.configService.loaded) {
      this.RoutesPrefix.push(this.configService.config.keycloak.url);
    }

    // Remove scheme://domain and leading /
    const path = req.url.replace(/^(https?:\/\/)(.*?)\//, '');

    if (this.RoutesPrefix.some((route) => req.url.startsWith(route)) ||
      this.unauthenticatedRoutesPrefix.some((route) => path.startsWith(route)) ||
      !this.authenticatedRoutesPrefix.some((route) => path.startsWith(route))) {
      return next.handle(req);
    }

    return next.handle(this.addPolicies(req))
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401 && !this.gettingRefreshToken) {
            this.gettingRefreshToken = true;

            return this.oidcSecurityService.forceRefreshSession()
              .pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => {
                  this.gettingRefreshToken = false;
                  return next.handle(this.addPolicies(req));
                }),
                catchError(() => {
                  this.authenticationService.logoffAndRevokeTokens();
                  return of (undefined);
                })
              );
          } else if (err.status === 403) {
            return this.router.navigateByUrl('unauthorized')
          } else {
            return throwError(err);
          }
        })
      );
  }

  /**
   * Increments a request with required bits for authentication
   * @param req The request to enhance
   */
  addPolicies(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.oidcSecurityService.getAccessToken();

    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      }
    });
  }
}
