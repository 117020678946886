<app-stretch-view>
  <!-- Header -->
  <!-- <app-header></app-header> -->
  <app-menu></app-menu>
  <mat-sidenav-container fxFlex>
    <!-- Sidenav -->
    <mat-sidenav fxFlex="400px" mode="side" opened  [disableClose]="true">
      <app-sidenav></app-sidenav>
    </mat-sidenav>
    <!-- Content -->
    <mat-sidenav-content class="sidenav-content-container" fxFlex>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-stretch-view>
