<div fxFlex fxLayout="column" class="tree-view-container">
  <mat-tree #structureTree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <mat-tree-node *matTreeNodeDef="let node" (click)="selectNode(node)" matTreeNodePadding
      [ngClass]="{ 'node': true, 'selectedNode': node.id === selectedNode?.id, 'not-selectable': !this.selectableStructureTypes.includes(node.type)}">
      <mat-icon class="node-type-icon">{{ nodeIcon(node) }}</mat-icon>
      {{node.nom}}
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <div class="mat-tree-node" (click)="selectNode(node)"
        [ngClass]="{ 'node': true, 'selectedNode': node === selectedNode, 'not-selectable': !this.selectableStructureTypes.includes(node.type)}">
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.nom">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-icon class="node-type-icon">{{ nodeIcon(node) }}</mat-icon>
        {{node.nom}}
      </div>
    </mat-tree-node>
  </mat-tree>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center" class="control-button-container">
    <button mat-icon-button mat-raised-button class="mat-elevation-z0" color="accent" (click)="closeTreeView.emit()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
