import { StructureWithSRU } from "./structure";
import { StructureRoleUtilisateur } from "./structureRoleUtilisateur";

export interface Utilisateur {
  id: number;
  utilisateurId: string;
  prenom: string;
  nom: string;
  email: string;
  telephone: string;
  sexe: string;
  rpps: string;
  adeli: string;
  fonction: FonctionUtilisateur;
  etat: EtatUtilisateur;
  derniereConnexion: Date;
  idstructure?: number;
  createdAt: Date;
  updatedAt: Date;
  structures?: StructureWithSRU[];
  relatedSRUs?: StructureRoleUtilisateur[];
  habilitations?: Habilitation[];
  nbHabilitations?: number;
  categorie: CategorieUtilisateur;
}

export interface Habilitation {
  structureNom: String;
  roles: string[]
}

export interface PostUtilisateurPayload {
  nom: string;
  prenom: string;
  fonction: FonctionUtilisateur;
  etat: EtatUtilisateur;
  email: string;
  sexe: string;
  telephone: string;
  rpps?: string;
  adeli?: string;
  affectations?: PostAffectation[];
  motif?: string;
  idstructure?: string;
  categorie: CategorieUtilisateur;
}

export interface PutUtilisateurPayload {
  nom?: string;
  prenom?: string;
  fonction?: FonctionUtilisateur;
  etat?: EtatUtilisateur;
  email?: string;
  sexe?: string;
  telephone?: string;
  rpps?: string;
  adeli?: string;
  affectations: PostAffectation[];
  categorie: CategorieUtilisateur;
}

export interface UtilisateurFilters {
  prenom?: string,
  nom?: string,
  email?: string,
  telephone?: string,
  fonction?: FonctionUtilisateur,
  rpps?: string,
  adeli?: string,
  sexe?: Sexe,
  etat?: EtatUtilisateur,
  structureId?: number,
}

export interface UtilisateurWithCount {
  rows: Utilisateur[];
  count: number;
}

export interface PostAffectation {
  roleId: number;
  structureId: number;
}

export enum Sexe {
  HOMME = 'Homme',
  FEMME = 'Femme'
}

export enum EtatUtilisateur {
  ACTIF = 'actif',
  INACTIF = 'inactif',
  PENDING = 'pending'
}

export enum FonctionUtilisateur {
  URGENTISTE = 'Urgentiste',  
  INFIRMIER = 'Infirmier',
  MEDECIN = 'Medecin non Urgentiste'
}

export enum CategorieUtilisateur {
  AUTRE = 'Autre',
  LIBERAL = 'Professionnel libéral',
  HOSPITALIER = 'Professionnel hospitalier',
  COORDINATION = 'Professionnel d’une structure de coordination'
}

export interface PasswordChangePayload {
  oldPassword: string;
  newPassword: string;
}
