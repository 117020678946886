// Libs
import { Component, OnInit } from '@angular/core';

// Services
import { AuthenticationService } from 'src/app/shared/services/auth/authentication.service';

// Components
import { SubscribableComponent } from 'src/app/shared/utils/subscribable-component';
import { AccountService } from 'src/app/shared/services/auth/account.service';

// Interfaces
import { Utilisateur } from 'src/app/shared/interfaces/utilisateur';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends SubscribableComponent implements OnInit {
  user: Utilisateur;

  constructor(
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.user = this.accountService.currentUser.value;
  }

  /**
   * Deconnects the user
   */
   logout(): void {
    this.authenticationService
      .logoffAndRevokeTokens();
  }
}
