import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Injectable, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Structure, StructureNode, StructureTreeView, StructureType } from '../../interfaces/structure';
import { SubscribableComponent } from '../../utils/subscribable-component';
import { StructureService } from "../../services/http/structure.service";
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { transformeStructureToTreeViewNode, transformeStructureToTreeViewTodoItemNode } from '../../utils/treeViewHelper';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { filterStructures } from '../../utils/autocomplete';
import { StorageService } from '../../services/business/storage.service';
import { ChecklistDatabase, TodoItemFlatNode, TodoItemNode } from './TreeDataSource';



@Component({
  selector: 'app-tree-view-select',
  templateUrl: './tree-view-select.component.html',
  styleUrls: ['./tree-view-select.component.scss'],
  providers: [ChecklistDatabase],
})
export class TreeViewSelectComponent {
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  public keepSelectedTreeView: number[] = [];

  public database: ChecklistDatabase;


  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  private _structures: Observable<Structure[]>;
  filteredGroupedStructures: Structure[];
  searchString = '';

  constructor(private _database: ChecklistDatabase, private structureService: StructureService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this._structures = this.structureService.fetchAll();
    this.database = this._database;
    this.loadData(_database);
  }
  affectationStructuresWorkspaceInstance(structures: Structure[]): Structure[] {
    let workspaces: Structure[] = []
    structures.forEach(structure => {
      if (structure.type === StructureType.WORKSPACE && structure.nbEnfants > 0) {
        structure.enfants = structures.filter(st => st.parentId === structure.id);
        if (!workspaces.includes(structure))
          workspaces.push(structure);
      }
    });
    return workspaces;
  }
  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  filterLeafNode(node: TodoItemFlatNode): boolean {
    if (!this.searchString) {
      return false
    }

  
    return node.item.toLowerCase()
      .indexOf(this.searchString?.toLowerCase()) === -1
  }

  loadData(_database: ChecklistDatabase) {
    this._structures.subscribe(structures => {
      this.filteredGroupedStructures = [...this.affectationStructuresWorkspaceInstance(structures)]
      _database.loadData(transformeStructureToTreeViewTodoItemNode(this.filteredGroupedStructures));
    })


    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }
  /**
   * Reset data & selection
   */
  resetTreeView() {
    this.loadData(new ChecklistDatabase());
    this.reloadSelection();
  }

  keydownSearch(){
    this.treeControl.collapseAll();
  }

  /**
   * Reset Selection treeview
   */
  reloadSelection() {
    this.checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
  }

  filterParentNode(node: TodoItemFlatNode): boolean {

    if (
      !this.searchString ||
      node.item.toLowerCase().indexOf(this.searchString?.toLowerCase()) !==
      -1
    ) {
      return false
    }
    const descendants = this.treeControl.getDescendants(node)

    if (
      descendants.some(
        (descendantNode) =>
          descendantNode.item
            .toLowerCase()
            .indexOf(this.searchString?.toLowerCase()) !== -1
      )
    ) {
      return false
    }

    return true
  }

  // public reloadData(){
  //   this._structures.subscribe(structures => {
  //     this.filteredGroupedStructures = [...this.affectationStructuresWorkspaceInstance(structures)]
  //     _database.loadData(transformeStructureToTreeViewTodoItemNode(this.filteredGroupedStructures));
  //   })
  // }

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.id = node.id;
    flatNode.type = node.type;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);

  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }


}



