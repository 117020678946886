// Libs
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Modules
import { OidcConfigModule } from 'src/app/shared/modules/oidc-config.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { SharedModule } from 'src/app/shared/modules/shared.module';

// Services
import { InterceptorService } from 'src/app/shared/services/core/interceptor.service';

// Components
import { AppComponent } from 'src/app/app.component';
// Layout
import { LayoutComponent } from 'src/app/shared/components/layout/layout.component';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { SidenavComponent } from 'src/app/shared/components/sidenav/sidenav.component';
import { IsSignedInGuard } from './shared/guard/IsSignedInGuard';


@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    SidenavComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    OidcConfigModule,
    SharedModule
  ],
  providers: [
    {
      // Add the http interceptor
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    IsSignedInGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
