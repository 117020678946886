// LIbs
import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { Module } from '../../interfaces/module';
import { ConfigService } from '../../services/core/config.service';
import { ModuleService } from '../../services/http/module.service';

// Interfaces

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  modules: Observable<Module[]>;
  constructor(
    private moduleService: ModuleService,
    protected configService: ConfigService,
    private oidc: OidcSecurityService,
    ) {
  }

  ngOnInit() {
    this.modules = this.moduleService.fetchModulesNavigation();
  }

  navigateToModule(module: Module) {
    this.oidc.logoffLocal();
    window.location.href = this.configService.config[module.nomConfig].baseUrl;
  }
}
