import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {Structure} from "../../interfaces/structure";
import {StructureService} from "../../services/http/structure.service";
import {debounceTime, filter, switchMap} from "rxjs/operators";
import {MAT_FORM_FIELD, MatFormField, MatFormFieldControl} from "@angular/material/form-field";
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {BooleanInput, coerceBooleanProperty} from "@angular/cdk/coercion";
import {FocusMonitor} from "@angular/cdk/a11y";

@Component({
  selector: 'app-structure-select',
  templateUrl: './structure-select.component.html',
  styleUrls: ['./structure-select.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: StructureSelectComponent}],
})
export class StructureSelectComponent implements ControlValueAccessor, MatFormFieldControl<Structure>, OnDestroy, OnInit, DoCheck {

  @ViewChild('structure') structureInput: ElementRef;

  static nextId = 0;

  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'structure-select';
  @HostBinding() id = `app-structure-select-${StructureSelectComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  selectedStructure: Structure | null;
  structures: Observable<Structure[]>
  searchText: BehaviorSubject<string>;

  get empty() {
    return !this.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  value: Structure | null;

  get errorState(): boolean {
    return !this.value && this.touched;
  }

  constructor(
    private structureService: StructureService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.searchText = new BehaviorSubject<string>('');
  }

  ngDoCheck(): void {
        if(this.ngControl.touched) {
          this.touched = true;
          this.stateChanges.next();
        }
    }

  ngOnInit(): void {
    this.structures = this.searchText.pipe(
      filter(txt => !!txt.length),
      debounceTime(500),
      switchMap(text => this.focused ? this.structureService.fetchAll({searchText: text}, 20, null, 'nom', 'ASC'): of([]))
    )
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.searchText.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
    }
    if(!this.value) {
      this.searchText.next('');
      this.structureInput.nativeElement.value = '';
    } else {
      this.searchText.next(this.value.nom)
      this.structureInput.nativeElement.value = this.displayFn(this.value);
    }
    this.stateChanges.next();
  }

  onContainerClick() {
    this._focusMonitor.focusVia(this.structureInput, 'program');
  }

  writeValue(structure: Structure | null): void {
    this.value = structure;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
    this.stateChanges.next();
  }

  filterOptions(value: string) {
    this.searchText.next(value)
  }

  displayFn(structure: Structure): string {
    if(!structure) {
      return '';
    }
    if(structure.metadata?.lieuIdentifiant && structure.nom) {
      return `${structure.metadata.lieuIdentifiant} - ${structure.nom}`
    }
    return structure.nom;
  }

  readonly autofilled: boolean;

  setDescribedByIds(ids: string[]): void {
  }

  readonly userAriaDescribedBy: string;

  onOptionSelected(structure: Structure) {
    this.value = structure;
    this._handleInput();
  }
}
