// Libs
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { ConfigService } from 'src/app/shared/services/core/config.service';
import { EntityService } from './entity.service';

// Interfaces
import {
  PostRattachement,
  PostStructurePayload,
  Structure,
  StructureFilters,
  StructureTreeView,
  StructureType
} from '../../interfaces/structure';
import { Ability } from '@casl/ability';
import { tap } from 'rxjs/operators';
import { StorageService } from '../business/storage.service';

@Injectable({
  providedIn: 'root'
})
export class StructureService extends EntityService<Structure> {

  constructor(
    configService: ConfigService,
    http: HttpClient,
    private ability: Ability,
    private storageService: StorageService,
  ) {
    super(configService, http, 'structure');
  }

  /**
   * Fetch all entities
   */
  public fetchAll(filters: StructureFilters = null, pageSize: number = null, pageIndex: number = null, sortBy: string = null, orderBy: string = null): Observable<Structure[]> {
    let params = new HttpParams();
    if (filters) {
      Object.entries(filters).map(([key, value]) => {
        params = params.set(key, (value as string));
      });
    }
    if (pageSize) params = params.set('limit', pageSize);
    if (pageIndex && pageSize) params = params.set('offset', pageIndex * pageSize);
    if (sortBy) params = params.set('sortBy', sortBy);
    if (orderBy) params = params.set('orderBy', orderBy);
    return this.http.get<Structure[]>(`${this.configService.config.admin.url}/${this.path}`, { params });
  }

  // public fetchAllRatachement(): Observable<Structure[]> {
  //   let params = new HttpParams();
  //   params = params.set('module', 'Admin');
  //   return this.http.get<Structure[]>(`${this.configService.config.admin.url}/${this.path}/module`, { params });
  // }

  /**
   * Fetch Structures for Tree view
   */
  public fetchStructuresTreeView(itemId: string | number = 0): Observable<StructureTreeView[]> {
    return this.http.get<StructureTreeView[]>(`${this.configService.config.admin.url}/structure/treeview/${itemId}`);
  }

  /**
 * Fetch All Structures
 */
  public fetchStructureForSelect(type?: StructureType, searchText?: string): Observable<Structure[]> {
    let params = new HttpParams();
    if (type) params = params.set('type', type);
    if (searchText) params = params.set('searchText', searchText);
    return this.http.get<Structure[]>(`${this.configService.config.admin.url}/structure/list`, { params });
  }


  /**
   * Fetch parent Structure
   */
  public fetchParentStructure(structureId: number, depth?: number): Observable<Structure> {
    let params = new HttpParams();
    if (depth) params = params.set('depth', depth);
    return this.http.get<Structure>(`${this.configService.config.admin.url}/structure/${structureId}/parent`, { params });
  }

  /**
 * Fetch childs Structure
 */
  public fetchChildStructures(structureId: number, depth?: number): Observable<Structure[]> {
    let params = new HttpParams();
    if (depth) params = params.set('depth', depth);
    return this.http.get<Structure[]>(`${this.configService.config.admin.url}/structure/${structureId}/child`, { params });
  }

  /**
   * Export Roles
   */
  public export(filters: StructureFilters = null, sortBy: string = null, orderBy: string = null): Observable<string> {
    let params = new HttpParams();
    if (filters) {
      Object.entries(filters).map(([key, value]) => {
        params = params.set(key, (value as string));
      });
    }
    if (sortBy) params = params.set('sortBy', sortBy);
    if (orderBy) params = params.set('orderBy', orderBy);
    return this.http.get(`${this.configService.config.admin.url}/structure/export`, { params, responseType: 'text' });
  }

  /**
   * Import Roles
   */
  public import(roleFile: File): Observable<Structure[]> {
    const formData: FormData = new FormData();
    formData.append('file', roleFile);
    return this.http.post<Structure[]>(`${this.configService.config.admin.url}/structure/import`, formData);
  }

  public register(postData: PostStructurePayload): Observable<Structure> {
    return this.http.post<Structure>(`${this.configService.config.admin.url}/structure`, postData)
      .pipe(tap((structure) => {
        const regles = this.ability.rules;
        regles.push({ action: 'readUtilisateur', subject: 'Admin', conditions: { structureId: structure.id } });
        regles.push({ action: 'createUtilisateur', subject: 'Admin', conditions: { structureId: structure.id } });
        regles.push({ action: 'updateUtilisateur', subject: 'Admin', conditions: { structureId: structure.id } });
        regles.push({ action: 'deleteUtilisateur', subject: 'Admin', conditions: { structureId: structure.id } });
        regles.push({ action: 'readStructure', subject: 'Admin', conditions: { structureId: structure.id } });
        regles.push({ action: 'createStructure', subject: 'Admin', conditions: { structureId: structure.id } });
        regles.push({ action: 'updateStructure', subject: 'Admin', conditions: { structureId: structure.id } });
        regles.push({ action: 'deleteStructure', subject: 'Admin', conditions: { structureId: structure.id } });
        this.ability.update(regles);
      }));
  }

  /**
   * Fetch structure hierarchy
   * return the list of structure from parents to structure itself
   */
  public fetchHierarchy(structureId: number): Observable<Structure[]> {

    return this.http.get<Structure[]>(`${this.configService.config.admin.url}/structure/${structureId}/hierarchy`);
  }

  /**
   * Attachement one instance with other instance. Fusion
   * @param postRattachement Objt content the value to attachement 
   * @returns 
   */
  // public attachementInstance(postRattachement: PostRattachement) {
  //   return this.http.post(`${this.configService.config.admin.url}/structure/rattachement`, postRattachement);
  // }

  toggleStructures(structures: Structure[]): void {
    this.storageService.setSelectedServices(structures);
  }
  toggleStructuresOne(structures: Structure[]): void {
    this.storageService.setSelectedServicesOne(structures);
  }

}
