import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Structure } from "../../interfaces/structure";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  readonly KEY_SELECTED_WORKSPACE = 'SELECTED_WORKSPACE';
  readonly KEY_SELECTED_SERVICES = 'SELECTED_SERVICES';
  readonly KEY_SELECTED_SERVICES_ONE = 'SELECTED_SERVICES_ONE';

  selectedWorkspace: BehaviorSubject<Structure>;
  selectedServices: BehaviorSubject<Structure[]>;
  selectedServicesOne: BehaviorSubject<Structure[]>;

  constructor() {
    this.selectedWorkspace = new BehaviorSubject(this.getSelectedWorkspaceFromStorage());
    this.selectedServices = new BehaviorSubject(this.getSelectedServicesFromStorage());
    this.selectedServicesOne = new BehaviorSubject(this.getSelectedServicesOneFromStorage());
  }

  getSelectedWorkspace(): Structure {
    return this.selectedWorkspace.value;
  }

  getSelectedServices(): Structure[] {
    return this.selectedServices.value;
  }
  getSelectedServiceOne(): Structure[] {
    return this.selectedServicesOne.value;
  }


  getSelectedWorkspaceFromStorage(): Structure {
    return sessionStorage[this.KEY_SELECTED_WORKSPACE] ? JSON.parse(sessionStorage[this.KEY_SELECTED_WORKSPACE]) as Structure : null;
  }

  getSelectedServicesFromStorage(): Structure[] {
    return sessionStorage[this.KEY_SELECTED_SERVICES] ? JSON.parse(sessionStorage[this.KEY_SELECTED_SERVICES]) as Structure[] : [];
  }
  getSelectedServicesOneFromStorage(): Structure[] {
    return sessionStorage[this.KEY_SELECTED_SERVICES_ONE] ? JSON.parse(sessionStorage[this.KEY_SELECTED_SERVICES_ONE]) as Structure[]: [];
  }


  setSelectedWorkspace(workspace: Structure): void {
    this.selectedWorkspace.next(workspace);
    sessionStorage[this.KEY_SELECTED_WORKSPACE] = JSON.stringify(workspace);
  }

  setSelectedServices(services: Structure[]): void {
    this.selectedServices.next(services);
    sessionStorage[this.KEY_SELECTED_SERVICES] = JSON.stringify(services);
  }
  
  setSelectedServicesOne(services: Structure[]): void {
    this.selectedServicesOne.next(services);
    sessionStorage[this.KEY_SELECTED_SERVICES_ONE] = JSON.stringify(services);
  }

  clearService() {
    sessionStorage[this.KEY_SELECTED_SERVICES] = ""
  }

  clearWorkSpace() {
    sessionStorage[this.KEY_SELECTED_WORKSPACE] = ""
  }
  clearServiceOne() {
    sessionStorage[this.KEY_SELECTED_SERVICES_ONE] = ""
  }

  /**
   * Clear the seession storage KEY_SELECTED_SERVICES & KEY_SELECTED_WORKSPACE & KEY_SELECTED_SERVICES_ONE
   */
  clearAll() {
    this.clearService();
    this.clearWorkSpace();
    this.clearServiceOne();
  }

}
