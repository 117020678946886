// Libs
import { Injectable } from '@angular/core';

// Services
import { NavElement } from 'src/app/shared/interfaces/sidenav';
import { StructureType } from '../../interfaces/structure';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  navElements: NavElement[] = [
    {
      label: 'DASHBOARD',
      routerLink: '/dashboard',
      icon: '',
      ruleAction: ''
    },
    {
      label: 'UTILISATEURS',
      routerLink: '/utilisateur',
      icon: 'person_outlined',
      ruleAction: 'utilisateurList'
    },
    {
      label: 'RÔLES',
      routerLink: '/role',
      icon: 'theater_comedy',
      ruleAction: 'readRole'
    },
    {
      label: 'TENANTS',
      routerLink: '/structure/tenant',
      icon: 'place_outlined',
      ruleAction: StructureType.TENANT,
    },
    {
      label: 'ETABLISSEMENTS',
      routerLink: '/structure/workspace',
      icon: 'workspaces_outlined',
      ruleAction: StructureType.WORKSPACE,
    },
    {
      label: 'SERVICES',
      routerLink: '/structure/instance',
      icon: 'group_work_outlined',
      ruleAction: StructureType.INSTANCE,
    },
    {
      label: 'DEMANDES',
      routerLink: '/demande',
      icon: 'inbox',
      ruleAction: 'manageDemande'
    },
  ];

  constructor() { }
}
