import { Role } from "./role";
import { StructureRoleUtilisateur } from "./structureRoleUtilisateur";

/**
 * Structure post payload
 */
 export interface PostStructurePayload {
  nom: string;
  type: StructureType;
  metadata: IMetadata;
  parentId: number;
}

/**
 * Structure put payload
 */
 export interface PutStructurePayload {
  nom?: string;
  metadata?: IMetadata;
  parentId?: number;
}

/**
 * Structure interface
 */
export interface Structure {
  id: number;
  nom: string;
  type: StructureType;
  metadata: IMetadata;
  parentId: number;
  grandParentNom?: string;
  parentNom?: string;
  departement?:string;
  parent?: Structure;
  createdAt?: Date;
  updatedAt?: Date;
  nbUtilisateurs?: number;
  nbEnfants?: number;
  relatedRoles?: Role[];
  label?: string;  
  enfants?: Structure[];
  disabled?: boolean;
}

export interface IMetadata {
  lieuIdentifiant?: string;
  lieuCommune?: string;
  description?:string
}

/**
 * Structure whith its related StructureRoleUtilisateur
 */
 export interface StructureWithSRU extends Structure {
  StructureRoleUtilisateur: StructureRoleUtilisateur;
}

export interface StructureFilters {
  nom?: string,
  parentId?: number;
  type?: StructureType;
  finess?: string;
  searchText?: string;
}

export interface StructureTreeView {
  id: number;
  type: StructureType;
  nom: string;
  parentId: number;
  enfants?: StructureTreeView[];
  level: number;
}

export interface StructureNode {
  id?: number;
 name: string;

 children?: StructureNode[];
}


export enum StructureType {
  STATE = 'STATE',
  TENANT = 'TENANT',
  WORKSPACE = 'WORKSPACE',
  INSTANCE = 'INSTANCE'
}

export interface PostRattachement{
  instanceDestinationId: number;
  instancesId:number[];
}

export interface AttachementStructure{

  displayWorkspace: Structure[];
  selectedWorkspace:Structure;
}
