import {CollectionViewer, DataSource, SelectionChange} from "@angular/cdk/collections";
import {StructureTreeView, StructureType} from "../../interfaces/structure";
import {BehaviorSubject, merge, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {FlatTreeControl} from "@angular/cdk/tree";
import {StructureService} from "../../services/http/structure.service";

export class TreeDataSource implements DataSource<StructureTreeView> {
  dataChange = new BehaviorSubject<StructureTreeView[]>([]);

  constructor(
    private treeControl: FlatTreeControl<StructureTreeView>,
    private structureService: StructureService,
  ) {}

  get data(): StructureTreeView[] {
    return this.dataChange.value;
  }
  set data(value: StructureTreeView[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  connect(collectionViewer: CollectionViewer): Observable<StructureTreeView[]> {
    this.treeControl.expansionModel.changed.subscribe(change => {
      if (
        (change as SelectionChange<StructureTreeView>).added ||
        (change as SelectionChange<StructureTreeView>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<StructureTreeView>);
      }
    });
    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<StructureTreeView>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: StructureTreeView, expand: boolean) {
    const subs = this.structureService.fetchStructuresTreeView(node.id).subscribe(children => {
      const index = this.data.indexOf(node);
      if (!children || index < 0) {
        // If no children, or cannot find the node, no op
        return;
      }

      if (expand) {
        const nodes = children.map(
          node => ({...node, level: TreeDataSource.getLevel(node)})
        );
        this.data.splice(index + 1, 0, ...nodes);
      } else {
        let count = this.toggleNodeIncrement(index, node);
        this.data.splice(index + 1, count);
      }
            // notify the change
      this.dataChange.next(this.data);
      subs.unsubscribe();
    });
  }

  /**
   * This method has been created to simplify toggleNode()
   * @param index the index mentionned in toggleNode()
   * @param node the node mentionned in toggleNode()
   * @returns count mentionned in toggleNode()
   */
  toggleNodeIncrement(index : number, node : StructureTreeView) : number  {
        let count = 0;
        for (
          let i = index + 1;
          i < this.data.length && this.data[i].level > node.level;
          i++, count++
        ) { /* This loop is used to increment count */ }
        return count;
  }

  static getLevel(node): number {
    return [StructureType.STATE, StructureType.TENANT, 'Departement', StructureType.WORKSPACE, StructureType.INSTANCE].findIndex(item => item === node.type)
  }

}
