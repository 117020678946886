// Lib imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AbilityModule } from '@casl/angular';
import { Ability, PureAbility } from '@casl/ability';

// Modules
import { MaterialModule } from 'src/app/shared/modules/material.module';

// Shared components
import { SubscribableComponent } from 'src/app/shared/utils/subscribable-component';
import { StretchViewComponent } from 'src/app/shared/components/stretch-view/stretch-view.component';
import { TableComponent } from '../components/table/table.component';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { TreeViewComponent } from '../components/tree-view/tree-view.component';
import { TreeViewSelectComponent } from '../components/tree-view-select/tree-view-select.component';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { MenuComponent } from '../components/menu/menu.component';
import {StructureSelectComponent} from "../components/structure-select/structure-select.component";
import { ProgressComponent } from '../components/progress/progress.component';
import { ProgressStepComponent } from '../components/progress/progress-step/progress-step.component';
import { ProgressStepDirective } from '../components/progress/progress-step.directive';

const components = [
  SubscribableComponent,
  StretchViewComponent,
  TableComponent,
  ConfirmDialogComponent,
  TreeViewComponent,
  TreeViewSelectComponent,
  BreadcrumbComponent,
  MenuComponent,
  StructureSelectComponent,
  ProgressComponent,
  ProgressStepComponent,
  ProgressStepDirective,
  
];

const modules = [
  CommonModule,
  RouterModule,
  MaterialModule,
  AbilityModule
];

const providers = [
  { provide: Ability, useValue: new Ability() },
  { provide: PureAbility, useExisting: Ability }
]

@NgModule({
  declarations: [
    components,
  ],
  imports: [
    modules
  ],
  exports: [
    modules,
    components
  ],
  providers //: []
})
export class SharedModule { }
