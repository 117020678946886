// Libs
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

// Services
import {EntityService} from './entity.service';
import {ConfigService} from 'src/app/shared/services/core/config.service';

// Interfaces
import {
  FonctionUtilisateur,
  Habilitation,
  PasswordChangePayload,
  PostUtilisateurPayload,
  Utilisateur,
  UtilisateurFilters
} from '../../interfaces/utilisateur';
import {map, tap} from 'rxjs/operators';
import {Ability} from '@casl/ability';
import {EntityFilters} from '../../interfaces/entity';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService extends EntityService<Utilisateur> {

  constructor(
    configService: ConfigService,
    http: HttpClient,
    private ability: Ability
  ) {
    super(configService, http, 'utilisateur');
  }

  /**
   * Fetch all entities
   */
     public fetchAndCountAll(filters: EntityFilters = null, pageSize: number = null, pageIndex: number = null, sortBy: string = null, orderBy: string = null): Observable<{rows: Utilisateur[], count: number }> {
      let params = new HttpParams();
      if (filters) {
        Object.entries(filters).map(([key, value]) => {
          params = params.set(key, (value as string));
        });
      }
      if (pageSize) params = params.set('limit', pageSize);
      if (pageIndex && pageSize) params = params.set('offset', pageIndex * pageSize);
      if (sortBy) params = params.set('sortBy', sortBy);
      if (orderBy) params = params.set('orderBy', orderBy);
      return this.http.get<{rows: Utilisateur[], count: any }>(`${this.configService.config.admin.url}/${this.path}`, { params });
    }

  /**
   * Fetch Users
   * @param withRelatedData if related data must be included whith user profile
   */
  public fetchUsersOfStructure(structureId: number): Observable<Utilisateur[]> {
    return this.http.get<Utilisateur[]>(`${this.configService.config.admin.url}/utilisateur/structure/${structureId}`);
  }

  /**
   * Fetch an entity by id
   * @param id
   */
  public fetchById(id: number, noStructures?: boolean): Observable<Utilisateur> {
    let params = {};
    if (noStructures === false) {
      params= { noStructures: false };
    }
    return this.http.get<Utilisateur>(`${this.configService.config.admin.url}/${this.path}/${id}`, { params })
      .pipe(
        map(utilisateur => {
          if (utilisateur.fonction?.toString() === 'Medecin'){
            utilisateur.fonction = FonctionUtilisateur.MEDECIN
          }
          return utilisateur;
        })
      );
  }

  /**
   * Fetch habilitations of a user
   * @param id
   */
  public fetchhabilitations(id: number): Observable<Habilitation[]> {
    return this.http.get<Habilitation[]>(`${this.configService.config.admin.url}/${this.path}/${id}/habilitation`);
  }

  /**
   * Fetch Self
   */
  public fetchMe(): Observable<Utilisateur> {
    return this.http.get<Utilisateur>(`${this.configService.config.admin.url}/utilisateur/me`);
  }

  /**
   * Export Utilisateurs
   */
  public export(filters: UtilisateurFilters = null, sortBy: string = null, orderBy: string = null): Observable<string> {
    let params = new HttpParams();
    if (filters) {
      Object.entries(filters).map(([key, value]) => {
        params = params.set(key, (value as string));
      });
    }
    if (sortBy) params = params.set('sortBy', sortBy);
    if (orderBy) params = params.set('orderBy', orderBy);
    return this.http.get(`${this.configService.config.admin.url}/utilisateur/export`, { params, responseType: 'text' });
  }

  /**
   * Import Utilisateurs
   */
  public import(roleFile: File): Observable<Utilisateur[]> {
    const formData: FormData = new FormData();
    formData.append('file', roleFile);
    return this.http.post<Utilisateur[]>(`${this.configService.config.admin.url}/utilisateur/import`, formData);
  }

  public register(postData: PostUtilisateurPayload): Observable<Utilisateur> {
    return this.http.post<Utilisateur>(`${this.configService.config.admin.url}/utilisateur`, postData)
      .pipe(tap((utilisateur) => {
        const regles = this.ability.rules;
        regles.push({ action: 'readUtilisateur', subject: 'Admin', conditions: { utilisateurId: utilisateur.id }});
        regles.push({ action: 'updateUtilisateur', subject: 'Admin', conditions: { utilisateurId: utilisateur.id }});
        regles.push({ action: 'deleteUtilisateur', subject: 'Admin', conditions: { utilisateurId: utilisateur.id }});
        this.ability.update(regles);
      }));
  }

  /**
   * Change password of the current user
   * @param Password payload containing old and new password
   */
  public changePassword(passwordChangePayload: PasswordChangePayload): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(`${this.configService.config.admin.url}/utilisateur/changePassword`, passwordChangePayload, {observe: 'response'});
  }

  public userExist(email: string): Observable<{ exist: boolean }>{
    let params = new HttpParams();
    if (email) {
      params = params.set('email', encodeURIComponent(email));
    }
    return this.http.get<{ exist: boolean }>(`${this.configService.config.admin.url}/utilisateur/exist`, { params });
  }
}
