import { environment } from 'src/environments/environment';
import { ConfigService } from './shared/services/core/config.service';
import { Config } from './shared/interfaces/config';
import { map } from 'rxjs/operators';
// Libs
import { HttpBackend, HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Modules
import { AppRoutingModule } from 'src/app/app-routing.module';
import { SharedModule } from 'src/app/shared/modules/shared.module';

// Services
import { InterceptorService } from 'src/app/shared/services/core/interceptor.service';

// Components
import { AppComponent } from 'src/app/app.component';
// Layout
import { LayoutComponent } from 'src/app/shared/components/layout/layout.component';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { SidenavComponent } from 'src/app/shared/components/sidenav/sidenav.component';
import { IsSignedInGuard } from './shared/guard/IsSignedInGuard';
import { AuthModule, OpenIdConfiguration, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';

/**
 * Initialize the config of the app
 * @param config The configuration service
 */
export const httpLoaderFactory = (httpBackend: HttpBackend, configService: ConfigService) => {
  let httpClient = new HttpClient(httpBackend);
  let req = httpClient.get<Config>(`${environment.api}/config/`);

  configService.loaded = req.pipe(
    map((config: Config): OpenIdConfiguration => {
      configService.config = config;
      return {
        authority: `${config.keycloak.url}/realms/si-urgence`,
        redirectUrl: `${config.admin.redirectUrl}`,
        postLogoutRedirectUri: `${config.admin.postLogoutRedirectUri}`,
        clientId: 'si-urgence_client',
        scope: 'openid email offline_access',
        responseType: 'code',
        useRefreshToken: true,
        // No nonce is provided when a acces token is issued using a refresh token using keycloak as a provider
        // See issue: https://github.com/damienbod/angular-auth-oidc-client/issues/552
        ignoreNonceAfterRefresh: true,
        startCheckSession: true,
      };
    })
  );

  return new StsConfigHttpLoader(configService.loaded);
};



@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    SidenavComponent
  ],
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend, ConfigService]
      }
    }),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    {
      // Add the http interceptor
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    IsSignedInGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
