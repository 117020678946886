import {Component, Input} from '@angular/core';
import {Structure} from '../../interfaces/structure';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() selectedStructure: Structure[];

  constructor() {
  }
}
