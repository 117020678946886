import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from 'src/app/shared/interfaces/config';
import { OpenIdConfiguration } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * Runtime configuration
   */
  public get config(): Config {
    return this.privateConfig;
  }

  /**
   * Runtime configuration
   */
  public set config(config: Config) {
    this.privateConfig = config;
  }

  private privateConfig: Config;
  public loaded: Observable<OpenIdConfiguration>;

  constructor() {
  }

}
