<app-stretch-view>
  <div fxLayout="column" class="sidenav-container" fxFlex fxLayoutGap="25px">
    <div class="sidenav-inner-container" fxLayout="column" fxFlex fxLayoutAlign="start stretch">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="sidenav-header">
        <div class="sidenav-title">SI-URGENCES</div>
      </div>
      <div class="user-container" fxLayout="row" fxLayoutAlign="space-between">
        <mat-icon class="sidenav-icon" (click)="account()">account_circle</mat-icon>
        <div fxLayout="column" fxFlex fxLayoutAlign="center center">
          <div class="user-label" *ngIf="user">{{user.prenom + " " + user.nom }}</div>
          <div class="logout pointer" (click)="logout()">Déconnexion</div>
        </div>
      </div>
      <ng-container *ngFor="let navElement of navElements">
        <div *ngIf="navElement.ruleAction | ablePure: 'Admin' | async" class="pointer nav-element-container"
        routerLinkActive="activated" [routerLink]="[navElement.routerLink]"
        fxLayout="row" fxLayoutAlign="space-between">
          <div class="navigation-label">{{navElement.label | uppercase}}</div>
        </div>
      </ng-container>
  </div>
  </div>
</app-stretch-view>
