import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {StructureTreeView, StructureType} from '../../interfaces/structure';
import {SubscribableComponent} from '../../utils/subscribable-component';
import {StructureService} from "../../services/http/structure.service";
import {TreeDataSource} from "./TreeDataSource";

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent extends SubscribableComponent implements OnChanges {
  @Output() validateSelection = new EventEmitter<StructureTreeView>();
  @Output() closeTreeView = new EventEmitter<any>();
  @Input() selectableStructureTypes: StructureType[];
  @Input() filterName: string;
  @Input() treeViewData: StructureTreeView[] = [];
  treeControl: FlatTreeControl<StructureTreeView>;
  dataSource: TreeDataSource;
  selectedNode: StructureTreeView = null;

  isExpandable = (node: StructureTreeView) => !node.enfants || node.enfants.length > 0;

  constructor(
    private route: ActivatedRoute,
    private structureService: StructureService,
  ) {
    super();
    this.treeControl = new FlatTreeControl<StructureTreeView>(this.getLevel, this.isExpandable);
    this.dataSource = new TreeDataSource(this.treeControl, this.structureService);
  }

  getLevel(node): number {
    return TreeDataSource.getLevel(node);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.treeViewData.currentValue) {
      this.dataSource.data = this.treeViewData.map(d => ({...d, level: this.getLevel(d)}));
      this.subs.push(this.route.queryParams.subscribe(queryParams => {
        if (queryParams[this.filterName] && this.dataSource.data.find(d => d.id == queryParams[this.filterName])) {
          this.selectNode(this.dataSource.data.find(d => d.id == queryParams[this.filterName]));
        }
      }));
    }
  }

  hasChild = (_: number, structure: StructureTreeView) => this.isExpandable(structure);

  nodeIcon(node: StructureTreeView): string {
    switch(node.type) {
      case StructureType.STATE:
        return 'flag';
      case StructureType.TENANT:
        return 'place_outlined';
      case StructureType.WORKSPACE:
        return 'workspaces_outlined';
      case StructureType.INSTANCE:
        return 'group_work_outlined';
    }
  }

  selectNode(node: StructureTreeView): void {
    if (this.selectableStructureTypes.includes(node.type)) {
      this.selectedNode = node;
      this.validateSelection.emit(this.selectedNode);
    }
  }
}
