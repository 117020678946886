import { NgModule } from '@angular/core';

// Angular-flex
import { FlexLayoutModule } from '@angular/flex-layout';

// Material components
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatTreeModule } from '@angular/material/tree';
import { MatMenuModule } from '@angular/material/menu';
import { getFrenchPaginatorIntl } from 'src/app/shared/components/table/table.component'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatStepperModule} from '@angular/material/stepper';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {MatListModule} from '@angular/material/list';
import { CdkTreeModule } from '@angular/cdk/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';


@NgModule({
  imports: [
    FlexLayoutModule,
    MatSidenavModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatChipsModule,
    MatTreeModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatStepperModule,
    CdkStepperModule,
    MatListModule,
    CdkTreeModule,
    MatCheckboxModule,
    FormsModule
  ],
  exports: [
    FlexLayoutModule,
    MatSidenavModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatChipsModule,
    MatTreeModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatStepperModule,
    CdkStepperModule,
    MatListModule,
    CdkTreeModule,
    MatCheckboxModule,
    FormsModule
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { panelClass: 'default-resume-snackbar' }
    },
    {
      provide: MatPaginatorIntl,
      useValue: getFrenchPaginatorIntl()
    }
  ]
})
export class MaterialModule { }
