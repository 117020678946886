
// Libs
import { StsConfigHttpLoader } from 'angular-auth-oidc-client';

// Services
import { ConfigService } from 'src/app/shared/services/core/config.service';

/**
 * Initialize factory dependencies
 */
export const initializeDeps = [
  ConfigService,
];

/**
 * Initialize the config of the app
 * @param config The configuration service
 */
 export const initializeFactory = (configService: ConfigService) => {
  const oidcConfig = configService.loadConfigs();

  return new StsConfigHttpLoader(oidcConfig);
};
