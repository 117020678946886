// TODO: what to do if no config is found ? oh snap page ?
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Config } from 'src/app/shared/interfaces/config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OpenIdConfiguration } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * Runtime configuration
   */
  public get config(): Config {
    return this.privateConfig;
  }

  /**
   * Runtime configuration
   */
  public set config(config: Config) {
    this.privateConfig = config;
  }

  /**
   * Has the config loaded
   */
  public get loaded(): boolean {
    return this.privateLoaded;
  }

  private privateConfig: Config;
  private privateLoaded: boolean;

  constructor(private http: HttpClient) { }

  /**
   * load the api config and build oidc config
   */
  loadConfigs(): Promise<OpenIdConfiguration> {
    return this.http.get<Config>(`${environment.api}/config/`)
    .pipe(
      map((config: Config): OpenIdConfiguration => {
        this.config = config;
        this.privateLoaded = true;

        return {
          authority: `${config.keycloak.url}/realms/si-urgence`,
          redirectUrl: `${config.admin.redirectUrl}`,
          postLogoutRedirectUri: `${config.admin.postLogoutRedirectUri}`,
          clientId: 'si-urgence_client',
          scope: 'openid email offline_access',
          responseType: 'code',
          useRefreshToken: true,
          // No nonce is provided when a acces token is issued using a refresh token using keycloak as a provider
          // See issue: https://github.com/damienbod/angular-auth-oidc-client/issues/552
          ignoreNonceAfterRefresh: true,
          startCheckSession: true,
        };
      })
    ).toPromise();
  }
}
