<div class="table-container" fxLayout="column" fxFlex>
  <table mat-table [dataSource]="paginable ? paginableDataSource : dataSource" matSort [matSortActive]="displayedColumns.includes('departement')? 'department' : null" matSortDirection="asc">
    <ng-container *ngFor="let dataColumn of displayedColumns; let index = index">
      <ng-container class="table-data" [matColumnDef]="dataColumn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="dataColumn === 'habilitations' || dataColumn === 'action'">
          {{ columnsLabels[index] }}
        </th>
        <td mat-cell *matCellDef="let element" [ngSwitch]="dataColumn">
          <ng-containter *ngSwitchCase="'action'" fxLayout="row">
            <button mat-icon-button (click)="edit.emit(element)" [disabled]="!canEdit(element)">
              <mat-icon class="table-icon pointer">edit_outline</mat-icon>
            </button>
            <button mat-icon-button (click)="delete(element)" [disabled]="!canDelete(element)">
              <mat-icon class="table-icon pointer">delete_outline</mat-icon>
            </button>
          </ng-containter>
          <ng-containter *ngSwitchCase="'grandParent'">
            {{ element.parent.parent.nom }}
          </ng-containter>
          <ng-containter *ngSwitchCase="'parent'">
            {{ element.parent.nom }}
          </ng-containter>
          <ng-containter *ngSwitchCase="'departement'">
               {{ element[dataColumn] }}
          </ng-containter>
          <ng-containter *ngSwitchCase="'nbUtilisateurs'">
            {{ element[dataColumn] }}
          </ng-containter>
          <ng-containter *ngSwitchCase="'nbEnfants'" class="pointer link" (click)="enfantClick.emit(element)">
            {{ element[dataColumn] }}
          </ng-containter>
          <ng-containter *ngSwitchCase="'derniereConnexion'">
            {{ element[dataColumn] | date: 'dd/MM/yyyy HH:mm' }}
          </ng-containter>
          <ng-containter *ngSwitchCase="'updatedAt'">
            {{ element[dataColumn] | date: 'dd/MM/yyyy HH:mm' }}
          </ng-containter>
          <ng-containter *ngSwitchCase="'etat'">
            {{ etats[element[dataColumn]] }}
          </ng-containter>
          <ng-containter *ngSwitchCase="'nbHabilitations'">
            <div [mdePopoverTriggerFor]="appPopover" mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger" (click)="getHabilitations(element.id)">
              {{ element[dataColumn] }}
            </div>
            <mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" mdePopoverPositionY="above">
              <mat-card class="habilitation-card" style="max-width: 500px">
                <mat-card-content class="habilitation-content">
                <ul class="habilitation-list">
                  <li class="habilitation-element" *ngFor="let habilitation of displayedHabilitations">
                    <label class="structure">{{ habilitation.structureNom + ' : '}}</label>
                    <label class="roles" *ngFor="let role of habilitation.roles; let index = index">{{ role }}<label *ngIf="index !== habilitation.roles.length - 1">, </label></label>
                  </li>
                </ul>
                </mat-card-content>
              </mat-card>
            </mde-popover>
          </ng-containter>
          <ng-containter *ngSwitchDefault>
            {{element[dataColumn]}}
          </ng-containter>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="paginable ? (paginableDataSource?.length$ | async) : null" [pageSizeOptions]="[13, 50, 100]" showFirstLastButtons></mat-paginator>
</div>
