import { TodoItemNode } from "../components/tree-view-select/TreeDataSource";
import { Structure, StructureType, StructureNode } from "../interfaces/structure";



export function transformeStructureToTreeViewNode(structures: Structure[]): StructureNode[] {
    const StructureNode: StructureNode[] = [];
    for (let index = 0; index < structures?.length; index++) {
        StructureNode.push({
            id: structures[index].id,
            name: structures[index].nom,
            children: structures[index]?.enfants ? enfantStructureNode(structures[index]?.enfants) : []
        })
    }
    return StructureNode;
}

function enfantStructureNode(structuresEnfant: Structure[]) {
    const StructureNode: StructureNode[] = [];
    for (let index = 0; index < structuresEnfant?.length; index++) {
        StructureNode.push({
            id: structuresEnfant[index].id,
            name: structuresEnfant[index].nom,
            children: []
        })
    }
    return StructureNode;
}


export function transformeStructureToTreeViewTodoItemNode(structures: Structure[]): TodoItemNode[] {
    const StructureNode: TodoItemNode[] = [];
    for (let index = 0; index < structures?.length; index++) {
        StructureNode.push({
            id: structures[index].id,
            item: structures[index].nom,
            children: structures[index]?.enfants ? enfantStructureTodoItemNode(structures[index]?.enfants) : [],
            type:StructureType.WORKSPACE

        })
    }
    return StructureNode;
}

function enfantStructureTodoItemNode(structuresEnfant: Structure[]) {
    const StructureNode: TodoItemNode[] = [];
    for (let index = 0; index < structuresEnfant?.length; index++) {
        StructureNode.push({
            id: structuresEnfant[index].id,
            item: structuresEnfant[index].nom,
            children:  [],
            type:StructureType.INSTANCE

        })
    }
    return StructureNode;
}

TodoItemNode